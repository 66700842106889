import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css']
})
export class ProcedureComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
