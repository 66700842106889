<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 12 12"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11 4.99994H3.41002L6.71002 1.70994C6.89832 1.52164 7.00411 1.26624 7.00411 0.999941C7.00411 0.73364 6.89832 0.478245 6.71002 0.289941C6.52172 0.101638 6.26632 -0.00415039 6.00002 -0.00415039C5.73372 -0.00415039 5.47832 0.101638 5.29002 0.289941L0.290018 5.28994C0.198978 5.38505 0.127613 5.49719 0.0800184 5.61994C-0.0199996 5.8634 -0.0199996 6.13648 0.0800184 6.37994C0.127613 6.50269 0.198978 6.61484 0.290018 6.70994L5.29002 11.7099C5.38298 11.8037 5.49358 11.8781 5.61544 11.9288C5.7373 11.9796 5.86801 12.0057 6.00002 12.0057C6.13203 12.0057 6.26274 11.9796 6.3846 11.9288C6.50645 11.8781 6.61706 11.8037 6.71002 11.7099C6.80375 11.617 6.87814 11.5064 6.92891 11.3845C6.97968 11.2627 7.00582 11.132 7.00582 10.9999C7.00582 10.8679 6.97968 10.7372 6.92891 10.6154C6.87814 10.4935 6.80375 10.3829 6.71002 10.2899L3.41002 6.99994H11C11.2652 6.99994 11.5196 6.89458 11.7071 6.70705C11.8947 6.51951 12 6.26516 12 5.99994C12 5.73472 11.8947 5.48037 11.7071 5.29283C11.5196 5.1053 11.2652 4.99994 11 4.99994Z"
    [attr.fill]="color"
  />
</svg>
