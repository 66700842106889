import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-arrow-up',
  templateUrl: './arrow-up.component.html',
  styleUrls: ['./arrow-up.component.css']
})
export class ArrowUpComponent {
  @Input() size=24;
  @Input() color='#000';
}
