<svg
[attr.width]="size"
[attr.height]="size"
  viewBox="0 0 12 12"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.71 5.29C11.617 5.19627 11.5064 5.12188 11.3846 5.07111C11.2627 5.02034 11.132 4.9942 11 4.9942C10.868 4.9942 10.7373 5.02034 10.6154 5.07111C10.4936 5.12188 10.383 5.19627 10.29 5.29L7 8.59V1C7 0.734784 6.89465 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73479 0 5.48043 0.105357 5.2929 0.292893C5.10536 0.48043 5 0.734784 5 1V8.59L1.71 5.29C1.5217 5.1017 1.2663 4.99591 1 4.99591C0.733701 4.99591 0.478306 5.1017 0.290002 5.29C0.101699 5.4783 -0.00408936 5.7337 -0.00408936 6C-0.00408936 6.2663 0.101699 6.5217 0.290002 6.71L5.29 11.71C5.38511 11.801 5.49725 11.8724 5.62 11.92C5.7397 11.9729 5.86913 12.0002 6 12.0002C6.13087 12.0002 6.2603 11.9729 6.38 11.92C6.50275 11.8724 6.6149 11.801 6.71 11.71L11.71 6.71C11.8037 6.61704 11.8781 6.50644 11.9289 6.38458C11.9797 6.26272 12.0058 6.13201 12.0058 6C12.0058 5.86799 11.9797 5.73728 11.9289 5.61542C11.8781 5.49356 11.8037 5.38296 11.71 5.29Z"
    [attr.fill]="color"
  />
</svg>
