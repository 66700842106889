<svg
  [attr.width]="size"
  [attr.height]="size*0.75"
  viewBox="0 0 16 12"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.71 1.21001C14.617 1.11628 14.5064 1.04189 14.3846 0.991122C14.2627 0.940353 14.132 0.914215 14 0.914215C13.868 0.914215 13.7373 0.940353 13.6154 0.991122C13.4936 1.04189 13.383 1.11628 13.29 1.21001L5.84 8.67001L2.71 5.53001C2.61348 5.43677 2.49954 5.36346 2.37468 5.31426C2.24983 5.26505 2.1165 5.24092 1.98232 5.24324C1.84814 5.24557 1.71573 5.27429 1.59265 5.32779C1.46957 5.38128 1.35824 5.45849 1.265 5.55501C1.17176 5.65154 1.09845 5.76548 1.04924 5.89033C1.00004 6.01519 0.975908 6.14851 0.97823 6.28269C0.980551 6.41687 1.00928 6.54928 1.06277 6.67236C1.11627 6.79544 1.19348 6.90677 1.29 7.00001L5.13 10.84C5.22296 10.9337 5.33356 11.0081 5.45542 11.0589C5.57728 11.1097 5.70799 11.1358 5.84 11.1358C5.97201 11.1358 6.10272 11.1097 6.22458 11.0589C6.34644 11.0081 6.45704 10.9337 6.55 10.84L14.71 2.68001C14.8115 2.58637 14.8925 2.47272 14.9479 2.34622C15.0033 2.21972 15.0319 2.08312 15.0319 1.94501C15.0319 1.80691 15.0033 1.67031 14.9479 1.54381C14.8925 1.41731 14.8115 1.30366 14.71 1.21001Z"
    [attr.fill]="color"
  />
</svg>
