import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
