<svg
[attr.width]="size"
[attr.height]="size*1.04"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_915_4219)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.12155 0.549805C11.3255 0.549805 13.9229 3.14713 13.9229 6.3511C13.9229 9.55507 11.3255 12.1524 8.12156 12.1524H6.99976V13.7878C6.99976 14.7231 5.8303 15.1465 5.23153 14.4279L2.44419 11.0831C0.964855 10.0318 -0.000263214 8.30416 -0.000263214 6.3511C-0.000263214 3.14713 2.59706 0.549805 5.80103 0.549805H8.12155Z"
      [attr.fill]="secondaryColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5183 7.5498C10.9184 7.5498 8 10.4682 8 14.0681C8 17.6681 10.9184 20.5865 14.5183 20.5865H15.7788V22.7654C15.7788 23.7006 16.9482 24.1241 17.547 23.4055L20.8974 19.385C22.5596 18.2038 23.644 16.2626 23.644 14.0681C23.644 10.4682 20.7257 7.5498 17.1257 7.5498H14.5183Z"
      [attr.fill]="primaryColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_915_4219">
      <rect
        width="24"
        height="24"
        fill="white"
        transform="translate(0 0.549805)"
      />
    </clipPath>
  </defs>
</svg>
