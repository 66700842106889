<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 20 20"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.71 10.71C14.6904 9.93866 15.406 8.88092 15.7572 7.68394C16.1085 6.48697 16.0779 5.21027 15.6698 4.03147C15.2617 2.85267 14.4963 1.83039 13.4801 1.10686C12.4639 0.383322 11.2474 -0.00549316 10 -0.00549316C8.75255 -0.00549316 7.53611 0.383322 6.51993 1.10686C5.50374 1.83039 4.73834 2.85267 4.33021 4.03147C3.92208 5.21027 3.89151 6.48697 4.24276 7.68394C4.59401 8.88092 5.3096 9.93866 6.29 10.71C4.61007 11.383 3.14428 12.4994 2.04889 13.9399C0.953495 15.3805 0.26956 17.0913 0.0699967 18.89C0.0555513 19.0213 0.0671132 19.1542 0.104022 19.2811C0.140931 19.4079 0.202464 19.5263 0.285108 19.6293C0.452016 19.8375 0.69478 19.9708 0.959997 20C1.22521 20.0292 1.49116 19.9518 1.69932 19.7849C1.90749 19.618 2.04082 19.3752 2.07 19.11C2.28958 17.1552 3.22168 15.3498 4.68822 14.0388C6.15475 12.7278 8.0529 12.003 10.02 12.003C11.9871 12.003 13.8852 12.7278 15.3518 14.0388C16.8183 15.3498 17.7504 17.1552 17.97 19.11C17.9972 19.3557 18.1144 19.5827 18.2991 19.747C18.4838 19.9114 18.7228 20.0015 18.97 20H19.08C19.3421 19.9698 19.5817 19.8373 19.7466 19.6313C19.9114 19.4252 19.9881 19.1624 19.96 18.9C19.7595 17.0962 19.0719 15.381 17.9708 13.9382C16.8698 12.4954 15.3969 11.3795 13.71 10.71ZM10 10C9.20887 10 8.43551 9.76541 7.77772 9.32588C7.11992 8.88636 6.60723 8.26164 6.30448 7.53074C6.00173 6.79983 5.92251 5.99557 6.07686 5.21964C6.2312 4.44372 6.61216 3.73099 7.17157 3.17158C7.73098 2.61217 8.44371 2.2312 9.21964 2.07686C9.99556 1.92252 10.7998 2.00173 11.5307 2.30448C12.2616 2.60724 12.8863 3.11993 13.3259 3.77772C13.7654 4.43552 14 5.20888 14 6C14 7.06087 13.5786 8.07828 12.8284 8.82843C12.0783 9.57858 11.0609 10 10 10Z"
    [attr.fill]="color"
  />
</svg>
