import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css'],
})
export class FacilityComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
