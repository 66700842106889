import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  SignUpModel,
  LoginModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  ChangePasswordModel,
} from '../models/auth.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public BACKEND_HOST = environment.url;
  constructor(private http: HttpClient) { }

  // register
  register(data: SignUpModel): any {
    return this.http
      .post<SignUpModel>(`${this.BACKEND_HOST}/auth/sign-up`, data)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  // log in
  login(credential: LoginModel): any {
    return this.http.post(`${this.BACKEND_HOST}/admin/login`, credential).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  // log in
  verifiyLogin(credential: LoginModel): any {
    return this.http
      .post(`${this.BACKEND_HOST}/admin/login-verification`, credential)
      .pipe(
        map((res: any) => {
          res = res.data;
          if (res && res.token) {
            sessionStorage.setItem('token', res.token);
            if (res.user.loginConfig) {
              sessionStorage.setItem(
                'loginConfig',
                JSON.stringify(res.user.loginConfig),
              );
            }
          }
          return res;
        }),
      );
  }

  // log out
  logout(): any {
    const accessToken = sessionStorage.getItem('token');
    if (accessToken) {
      sessionStorage.clear();
      return this.http
        .get(`${this.BACKEND_HOST}/admin/logout`, {
          headers: new HttpHeaders().set('X-Auth-Token', `${accessToken}`),
        })
        .pipe(
          map((res: any) => {
            return res.data;
          }),
        );
    }
  }

  //  ResetPassword
  forgetPassword(credential: ForgotPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/admin/forget-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  //  verifiyResetPasswordCode
  verifiyResetPasswordCode(credential: ResetPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/admin/verify-password-reset-code`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  //verifiyResetPasswordCode
  resetPassword(credential: ResetPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/admin/reset-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          res = res.data;
          if (res && res.user) {
            sessionStorage.setItem('token', res.user.token);
            if (res.user.loginConfig) {
              sessionStorage.setItem(
                'loginConfig',
                JSON.stringify(res.user.loginConfig),
              );
            }
          }

          return res;
        })
      );
  }

  //verifiyResetPasswordCode
  changePassword(credential: ChangePasswordModel): any {
    return this.http
      .put<ChangePasswordModel>(
        `${this.BACKEND_HOST}/admin/change-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  isLoggedIn(): boolean {
    return !!sessionStorage.getItem('token');
  }

  hasResetPassword(): boolean {
    let loginConfig: any = sessionStorage.getItem('loginConfig');
    loginConfig = loginConfig ? JSON.parse(loginConfig) : null;
    if (loginConfig && loginConfig.hasResetPassword === true) {
      return true;
    } else {
      return false;
    }
  }
}
