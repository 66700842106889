import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css'],
})
export class AudioComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
