import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
export class MedicationComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
