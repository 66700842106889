import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css'],
})
export class ProgressComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
