<svg
  [attr.width]="size"
  [attr.height]="size * 1.18"
  viewBox="0 0 38 45"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M37.7 12.8117L37.6992 12.7698C37.6784 11.6728 37.6588 10.6367 37.6588 9.6399C37.6588 8.12983 36.4303 6.90133 34.9202 6.90133C29.0773 6.90133 24.6428 5.23547 20.9644 1.65885C19.8938 0.617679 18.2165 0.617593 17.146 1.65877C13.4677 5.23547 9.03364 6.90133 3.19165 6.90133C1.68158 6.90133 0.453082 8.12983 0.453082 9.6399C0.453082 10.658 0.433061 11.7045 0.411837 12.8126C0.313021 17.9855 0.19006 24.4233 2.35052 30.267C3.53845 33.4803 5.33716 36.2727 7.69671 38.5668C10.3841 41.1796 13.9039 43.2521 18.158 44.7267C18.4473 44.8271 18.7491 44.878 19.0551 44.878C19.3614 44.878 19.6634 44.827 19.952 44.7268C24.2068 43.252 27.7268 41.1795 30.4145 38.5666C32.7742 36.2725 34.5731 33.4799 35.7612 30.2665C37.9218 24.4225 37.7988 17.9846 37.7 12.8117ZM33.3432 29.3726C31.0647 35.535 26.4083 39.7608 19.1069 42.2915C19.0736 42.303 19.037 42.3032 19.0024 42.2912C11.7024 39.7608 7.04633 35.5353 4.76824 29.3732C2.77663 23.9859 2.88945 18.076 2.98904 12.8618C3.01052 11.7412 3.0308 10.6828 3.0308 9.6399C3.0308 9.55131 3.10289 9.47913 3.19157 9.47913C9.74769 9.47913 14.7528 7.58144 18.9431 3.50679C18.9744 3.47629 19.0147 3.461 19.055 3.461C19.0953 3.461 19.1357 3.47638 19.1672 3.50688C23.3576 7.58144 28.3632 9.47913 34.9201 9.47913C35.0087 9.47913 35.0809 9.55131 35.0809 9.6399C35.0809 10.6612 35.1007 11.7092 35.1219 12.8187L35.1226 12.8608V12.8609C35.2223 18.0752 35.3351 23.9851 33.3432 29.3726Z"
    [attr.fill]="color"
  />
  <path
    d="M27.0644 18.589H22.9171V14.4412C22.9171 13.7294 22.34 13.1523 21.6282 13.1523H16.4841C15.7723 13.1523 15.1952 13.7294 15.1952 14.4412V18.589H11.0472C10.3354 18.589 9.7583 19.1661 9.7583 19.8779V25.022C9.7583 25.7338 10.3354 26.3109 11.0472 26.3109H15.1952V30.4586C15.1952 31.1704 15.7723 31.7475 16.4841 31.7475H21.6282C22.34 31.7475 22.9171 31.1704 22.9171 30.4586V26.3109H27.0645C27.7763 26.3109 28.3534 25.7338 28.3534 25.022V19.8779C28.3533 19.166 27.7762 18.589 27.0644 18.589ZM25.7755 23.7331H21.6282C20.9164 23.7331 20.3393 24.3102 20.3393 25.022V29.1697H17.773V25.022C17.773 24.3102 17.1959 23.7331 16.4841 23.7331H12.3361V21.1668H16.4841C17.1959 21.1668 17.773 20.5897 17.773 19.8779V15.7301H20.3393V19.8779C20.3393 20.5897 20.9164 21.1668 21.6282 21.1668H25.7756V23.7331H25.7755Z"
    [attr.fill]="color"
  />
</svg>
