<svg
  [attr.width]="size"
  [attr.height]="size * 0.91"
  viewBox="0 0 35 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M34.0537 10.2921C33.899 2.56588 24.4709 -3.12813 17.0557 3.35578C9.64542 -3.12395 0.21235 2.56209 0.0575856 10.2921C0.000860669 13.1241 1.20105 15.8509 3.52823 18.1775C3.53454 18.1842 8.25207 22.8758 16.3528 30.9324C16.7415 31.3191 17.3697 31.3192 17.7585 30.9324L30.5831 18.1775C32.9103 15.8509 34.1104 13.1241 34.0537 10.2921ZM17.0557 28.8207L6.11347 17.9379H9.61447C9.96452 17.9379 10.2889 17.7543 10.469 17.4542L11.2339 16.1797L14.0888 20.6648C14.5086 21.3245 15.4945 21.2649 15.8302 20.5564L19.7701 12.2409L22.5562 17.414C22.7301 17.7367 23.067 17.938 23.4337 17.938H27.9977L17.0557 28.8207ZM29.9256 15.9452H24.029L20.5907 9.56131C20.2052 8.84567 19.1629 8.86785 18.8126 9.60714L14.8015 18.0728L12.0498 13.7498C11.6486 13.1192 10.7321 13.143 10.3545 13.7721L9.05027 15.9453H4.18595C2.74644 14.2144 2.01114 12.2921 2.05033 10.332C2.17474 4.11944 10.094 -0.83104 16.351 5.42437C16.7398 5.81315 17.3712 5.81348 17.7603 5.42437C25.7492 -2.56268 36.7321 7.76232 29.9256 15.9452Z"
    [attr.fill]="color"
  />
</svg>
