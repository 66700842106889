import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
