import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-arrow-down',
  templateUrl: './arrow-down.component.html',
  styleUrls: ['./arrow-down.component.css']
})
export class ArrowDownComponent {
  @Input() size=24;
  @Input() color='#000';
}
