import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.css']
})
export class CloseComponent {
  @Input() size=24;
  @Input() color='#000';
}
