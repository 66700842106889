import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from '../shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  //VARIABLES
  title = 'VIUHEALTH ADMIN PORTAL';
  loading = false;

  //CONSTRUCTOR
  constructor(public loadingService: LoadingService) {}

  //LIFECYCLE
  ngOnInit(): void {
    this.listenToLoading();
  }

  //FUNCTIONS
  listenToLoading(): void {
    this.loadingService.isLoading.pipe(delay(0)).subscribe((loading) => {
      this.loading = loading;
    });
  }
}
