import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
