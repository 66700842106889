<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 35 35"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_889_21176)">
    <path
      d="M20.8337 32.3895C17.2734 33.3205 13.5006 32.9039 10.2106 31.2159C6.89754 29.5161 4.34814 26.6597 3.03204 23.1728C1.81322 19.9435 1.73433 16.5062 2.80387 13.2324C3.59689 10.8049 5.0047 8.61655 6.85458 6.89311L6.51338 9.72467L8.50145 9.96433L9.25363 3.72282L3.09048 2.99414L2.85533 4.98274L5.61796 5.30946C3.45837 7.28072 1.81701 9.80528 0.900536 12.6106C0.31251 14.4105 0.0303503 16.2792 0.0619596 18.1646C0.0946978 20.1156 0.46365 22.0386 1.15872 23.8801C2.65054 27.8327 5.54066 31.0708 9.29666 32.9977C11.7243 34.2431 14.3839 34.8778 17.0587 34.8778C18.4927 34.8778 19.9313 34.6953 21.3404 34.3267L20.8337 32.3895Z"
      [attr.fill]="color"
    />
    <path
      d="M33.0248 23.6854C34.4172 19.8737 34.3936 15.6803 32.9584 11.8778C31.4043 7.76013 28.3625 4.44693 24.3933 2.54851C20.4367 0.656205 15.9664 0.360565 11.8057 1.71618L12.426 3.62011C16.0955 2.42453 20.0386 2.68558 23.5292 4.35503C27.0306 6.02966 29.7139 8.95239 31.0849 12.585C32.3507 15.9389 32.3716 19.6371 31.1439 22.9984C30.1729 25.6564 28.4739 27.9753 26.2691 29.6932L26.6403 26.6136L24.6522 26.374L23.8999 32.6155L30.063 33.3442L30.2982 31.3556L27.7709 31.0567C30.1383 29.1327 31.9648 26.5873 33.0248 23.6854Z"
      [attr.fill]="color"
    />
    <path
      d="M25.9752 20.3604C25.9752 17.8099 24.3739 15.6267 22.124 14.7623C22.7013 14.0373 23.0475 13.1204 23.0475 12.1237C23.0475 9.78321 21.1434 7.87915 18.8029 7.87915C16.6536 7.87915 14.8736 9.48518 14.5968 11.5599C12.5221 11.8366 10.9161 13.6167 10.9161 15.7659C10.9161 16.7772 11.2721 17.7065 11.8646 18.4365C9.65782 19.3218 8.09521 21.483 8.09521 24.0026V25.0038H22.2414V24.0026C22.2414 23.0569 22.021 22.162 21.6294 21.3658L25.9752 21.3603V20.3604ZM18.8029 9.88163C20.0392 9.88163 21.0451 10.8874 21.0451 12.1237C21.0451 13.2394 20.2258 14.1671 19.1573 14.3375C18.7296 13.1444 17.7823 12.1971 16.5891 11.7694C16.7595 10.7009 17.6872 9.88163 18.8029 9.88163ZM15.1608 13.5239C16.397 13.5239 17.4029 14.5297 17.4029 15.766C17.4029 17.0024 16.397 18.0081 15.1608 18.0081C13.9244 18.0081 12.9186 17.0023 12.9186 15.766C12.9186 14.5297 13.9244 13.5239 15.1608 13.5239ZM10.2248 23.0014C10.6702 21.2832 12.2342 20.0106 14.0898 20.0106H16.247C18.1025 20.0106 19.6664 21.2832 20.1119 23.0014H10.2248ZM20.0413 19.3654C19.5689 18.9781 19.0366 18.6616 18.4605 18.4318C18.9311 17.8504 19.2513 17.1428 19.3619 16.3684H19.9808C21.8368 16.3684 23.4012 17.6416 23.8461 19.3606L20.0413 19.3654Z"
      [attr.fill]="color"
    />
  </g>
  <defs>
    <clipPath id="clip0_889_21176">
      <rect
        width="34"
        height="34"
        fill="white"
        transform="translate(0.0556641 0.877686)"
      />
    </clipPath>
  </defs>
</svg>
