import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-check-circle',
  templateUrl: './check-circle.component.html',
  styleUrls: ['./check-circle.component.css']
})
export class CheckCircleComponent {
  @Input() size=24;
  @Input() color='#000';
}
