import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-angle-right',
  templateUrl: './angle-right.component.html',
  styleUrls: ['./angle-right.component.css']
})
export class AngleRightComponent {
  @Input() size=24;
  @Input() color='#000';
}
