<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 16 16"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.5151 1.07073C13.9056 0.680201 14.5388 0.680201 14.9293 1.07073C15.3198 1.46125 15.3198 2.09441 14.9293 2.48494L9.41421 8.00005L14.9293 13.5152C15.3198 13.9057 15.3198 14.5389 14.9293 14.9294C14.5388 15.3199 13.9056 15.3199 13.5151 14.9294L7.99999 9.41427L2.48488 14.9294C2.09435 15.3199 1.46119 15.3199 1.07066 14.9294C0.68014 14.5389 0.68014 13.9057 1.07066 13.5152L6.58578 8.00005L1.07066 2.48494C0.68014 2.09441 0.68014 1.46125 1.07066 1.07073C1.46119 0.680201 2.09435 0.680201 2.48488 1.07073L7.99999 6.58584L13.5151 1.07073Z"
    [attr.fill]="color"
  />
</svg>
