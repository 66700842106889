<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 12 12"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.71 5.29002L6.71 0.290018C6.6149 0.198978 6.50275 0.127613 6.38 0.0800184C6.13654 -0.0199996 5.86346 -0.0199996 5.62 0.0800184C5.49725 0.127613 5.38511 0.198978 5.29 0.290018L0.290002 5.29002C0.196764 5.38326 0.122803 5.49395 0.0723429 5.61577C0.0218825 5.73759 -0.00408936 5.86816 -0.00408936 6.00002C-0.00408936 6.26632 0.101699 6.52172 0.290002 6.71002C0.478306 6.89832 0.733701 7.00411 1 7.00411C1.2663 7.00411 1.5217 6.89832 1.71 6.71002L5 3.41002V11C5 11.2652 5.10536 11.5196 5.2929 11.7071C5.48043 11.8947 5.73479 12 6 12C6.26522 12 6.51957 11.8947 6.70711 11.7071C6.89465 11.5196 7 11.2652 7 11V3.41002L10.29 6.71002C10.383 6.80375 10.4936 6.87814 10.6154 6.92891C10.7373 6.97968 10.868 7.00582 11 7.00582C11.132 7.00582 11.2627 6.97968 11.3846 6.92891C11.5064 6.87814 11.617 6.80375 11.71 6.71002C11.8037 6.61706 11.8781 6.50645 11.9289 6.3846C11.9797 6.26274 12.0058 6.13203 12.0058 6.00002C12.0058 5.86801 11.9797 5.7373 11.9289 5.61544C11.8781 5.49358 11.8037 5.38298 11.71 5.29002Z"
    [attr.fill]="color"
  />
</svg>
