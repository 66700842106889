import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-eye',
  templateUrl: './eye.component.html',
  styleUrls: ['./eye.component.css']
})
export class EyeComponent {
  @Input() size=24;
  @Input() color='#000';

}
