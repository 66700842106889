<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 20 20"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.6839 8.72954C15.0869 8.35181 15.1073 7.71897 14.7295 7.31606C14.3518 6.91315 13.719 6.89273 13.3161 7.27046L8.73186 11.5681L6.74741 9.33564C6.38049 8.92285 5.74842 8.88567 5.33564 9.25259C4.92285 9.61951 4.88567 10.2516 5.25259 10.6644L7.91926 13.6644C8.09851 13.866 8.35165 13.9867 8.62118 13.999C8.89071 14.0112 9.15377 13.9141 9.35061 13.7295L14.6839 8.72954Z"
    [attr.fill]="color"
  />
</svg>
