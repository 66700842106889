import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { ResetPasswordGuard } from '../shared/guard/reset-password.guard';

import { AuthGuard } from '../shared/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../modules/auth/auth.module').then((auth) => auth.AuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, ResetPasswordGuard],
    loadChildren: () =>
      import('../modules/dashboard/dashboard.module').then(
        (dashboard) => dashboard.DashboardModule,
      ),
  },
  {
    path: 'patient',
    canActivate: [AuthGuard, ResetPasswordGuard],
    loadChildren: () =>
      import('../modules/patient/patient.module').then(
        (user) => user.UserModule,
      ),
  },
  { path: '**', redirectTo: 'dashbaord' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
