import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-file-secure',
  templateUrl: './file-secure.component.html',
  styleUrls: ['./file-secure.component.css'],
})
export class FileSecureComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
