import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-eye-slash',
  templateUrl: './eye-slash.component.html',
  styleUrls: ['./eye-slash.component.css'],
})
export class EyeSlashComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
