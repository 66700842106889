<svg
[attr.width]="size"
[attr.height]="size*1.5"
  viewBox="0 0 8 14"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.500012 7.80005L6.20001 13.4C6.60001 13.8 7.20001 13.8 7.60001 13.4C8.00001 13 8.00001 12.4 7.60001 12L2.70001 7.00005L7.60001 2.00005C8.00001 1.60005 8.00001 1.00005 7.60001 0.600049C7.40001 0.400049 7.20001 0.300049 6.90001 0.300049C6.60001 0.300049 6.40001 0.400049 6.20001 0.600049L0.500012 6.20005C0.100012 6.70005 0.100012 7.30005 0.500012 7.80005C0.500012 7.70005 0.500012 7.70005 0.500012 7.80005Z"
    [attr.fill]="color"
  />
</svg>
