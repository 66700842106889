import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.css'],
})
export class PulseComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
