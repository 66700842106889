<svg
  [attr.width]="size"
  [attr.height]="size*0.8"
  viewBox="0 0 20 16"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.92 7.6C17.9 2.91 14.1 0 10 0C5.9 0 2.1 2.91 0.08 7.6C0.0249367 7.72617 -0.00348473 7.86234 -0.00348473 8C-0.00348473 8.13766 0.0249367 8.27383 0.08 8.4C2.1 13.09 5.9 16 10 16C14.1 16 17.9 13.09 19.92 8.4C19.9751 8.27383 20.0035 8.13766 20.0035 8C20.0035 7.86234 19.9751 7.72617 19.92 7.6ZM10 14C6.83 14 3.83 11.71 2.1 8C3.83 4.29 6.83 2 10 2C13.17 2 16.17 4.29 17.9 8C16.17 11.71 13.17 14 10 14ZM10 4C9.20887 4 8.43552 4.2346 7.77772 4.67412C7.11992 5.11365 6.60723 5.73836 6.30448 6.46927C6.00173 7.20017 5.92252 8.00444 6.07686 8.78036C6.2312 9.55628 6.61216 10.269 7.17157 10.8284C7.73098 11.3878 8.44372 11.7688 9.21964 11.9231C9.99556 12.0775 10.7998 11.9983 11.5307 11.6955C12.2616 11.3928 12.8864 10.8801 13.3259 10.2223C13.7654 9.56448 14 8.79113 14 8C14 6.93913 13.5786 5.92172 12.8284 5.17157C12.0783 4.42143 11.0609 4 10 4ZM10 10C9.60444 10 9.21776 9.8827 8.88886 9.66294C8.55996 9.44318 8.30362 9.13082 8.15224 8.76537C8.00087 8.39991 7.96126 7.99778 8.03843 7.60982C8.1156 7.22186 8.30608 6.86549 8.58579 6.58579C8.86549 6.30608 9.22186 6.1156 9.60982 6.03843C9.99778 5.96126 10.3999 6.00087 10.7654 6.15224C11.1308 6.30362 11.4432 6.55996 11.6629 6.88886C11.8827 7.21776 12 7.60444 12 8C12 8.53043 11.7893 9.03914 11.4142 9.41421C11.0391 9.78929 10.5304 10 10 10Z"
    [attr.fill]="color"
  />
</svg>
