import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent {
  @Input() size=24;
  @Input() primaryColor='#000';
  @Input() secondaryColor='#00000050';
}
