import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class CheckComponent {
  @Input() size=24;
  @Input() color='#000';
}
