import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-bell',
  templateUrl: './bell.component.html',
  styleUrls: ['./bell.component.css']
})
export class BellComponent {
  @Input() size=24;
  @Input() color='#000';
}
