<svg
[attr.width]="size"
[attr.height]="size*1.04"
  viewBox="0 0 24 25"
  [attr.fill]="primaryColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_888_17572)">
    <path
      d="M5.22377 17.6974C3.4645 15.9381 3.53585 13.0646 5.38026 11.3948L5.46784 11.3155C7.45947 9.51241 10.6031 9.9838 11.9782 12.2917C13.3041 10.0191 16.3867 9.54524 18.3339 11.3147L18.5847 11.5426C20.4491 13.2368 20.5186 16.1456 18.7373 17.9269L13.5095 23.1547C12.7285 23.9358 11.4621 23.9358 10.6811 23.1547L5.22377 17.6974Z"
      [attr.fill]="primaryColor"
    />
    <circle cx="12" cy="4.5498" r="4"   [attr.fill]="secondaryColor" />
  </g>
  <defs>
    <clipPath id="clip0_888_17572">
      <rect
        width="24"
        height="24"
        fill="white"
        transform="translate(0 0.549805)"
      />
    </clipPath>
  </defs>
</svg>
