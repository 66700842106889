import { NgModule } from '@angular/core';
import { IconsLibraryComponent } from './icons-library.component';
import { ArrowLeftComponent } from './arrow-left/arrow-left.component';
import { CommonModule } from '@angular/common';
import { EyeComponent } from './eye/eye.component';
import { EyeSlashComponent } from './eye-slash/eye-slash.component';
import { ArrowRightComponent } from './arrow-right/arrow-right.component';
import { CloseComponent } from './close/close.component';
import { CloseCircleComponent } from './close-circle/close-circle.component';
import { CloseCircleFilledComponent } from './close-circle-filled/close-circle-filled.component';
import { CheckCircleFilledComponent } from './check-circle-filled/check-circle-filled.component';
import { CheckCircleComponent } from './check-circle/check-circle.component';
import { CheckComponent } from './check/check.component';
import { NotificationComponent } from './notification/notification.component';
import { AngleLeftComponent } from './angle-left/angle-left.component';
import { AngleRightComponent } from './angle-right/angle-right.component';
import { AngleUpComponent } from './angle-up/angle-up.component';
import { AngleDownComponent } from './angle-down/angle-down.component';
import { ArrowUpComponent } from './arrow-up/arrow-up.component';
import { ArrowDownComponent } from './arrow-down/arrow-down.component';
import { FileMedicalAltComponent } from './file-medical-alt/file-medical-alt.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { UsersComponent } from './users/users.component';
import { UserCardComponent } from './user-card/user-card.component';
import { PulseComponent } from './pulse/pulse.component';
import { MenuComponent } from './menu/menu.component';
import { WellnessComponent } from './wellness/wellness.component';
import { NotesComponent } from './notes/notes.component';
import { CardiogramComponent } from './cardiogram/cardiogram.component';
import { HealthInsuranceComponent } from './health-insurance/health-insurance.component';
import { FlaskComponent } from './flask/flask.component';
import { UsersExchangeComponent } from './users-exchange/users-exchange.component';
import { InfoCircleComponent } from './info-circle/info-circle.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessComponent } from './access/access.component';
import { AudioComponent } from './audio/audio.component';
import { FacilityComponent } from './facility/facility.component';
import { FoodComponent } from './food/food.component';
import { ProgressComponent } from './progress/progress.component';
import { MedicationComponent } from './medication/medication.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { EmailComponent } from './email/email.component';
import { TaskComponent } from './task/task.component';
import { FileSecureComponent } from './file-secure/file-secure.component';
import { FileEditComponent } from './file-edit/file-edit.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { BellComponent } from './bell/bell.component';



@NgModule({
  declarations: [
    IconsLibraryComponent,
    ArrowLeftComponent,
    EyeComponent,
    EyeSlashComponent,
    ArrowRightComponent,
    CloseComponent,
    CloseCircleComponent,
    CloseCircleFilledComponent,
    CheckCircleFilledComponent,
    CheckCircleComponent,
    CheckComponent,
    NotificationComponent,
    AngleLeftComponent,
    AngleRightComponent,
    AngleUpComponent,
    AngleDownComponent,
    ArrowUpComponent,
    ArrowDownComponent,
    FileMedicalAltComponent,
    HomeComponent,
    UserComponent,
    UsersComponent,
    UserCardComponent,
    PulseComponent,
    MenuComponent,
    WellnessComponent,
    NotesComponent,
    BellComponent,
    CardiogramComponent,
    HealthInsuranceComponent,
    FlaskComponent,
    UsersExchangeComponent,
    InfoCircleComponent,
    DashboardComponent,
    AccessComponent,
    AudioComponent,
    FacilityComponent,
    FoodComponent,
    ProgressComponent,
    MedicationComponent,
    AssessmentComponent,
    EmailComponent,
    TaskComponent,
    FileSecureComponent,
    FileEditComponent,
    ProcedureComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IconsLibraryComponent,
    ArrowLeftComponent,
    EyeComponent,
    EyeSlashComponent,
    ArrowRightComponent,
    CloseComponent,
    CloseCircleComponent,
    CloseCircleFilledComponent,
    CheckCircleFilledComponent,
    CheckCircleComponent,
    CheckComponent,
    NotificationComponent,
    AngleLeftComponent,
    AngleRightComponent,
    AngleUpComponent,
    AngleDownComponent,
    ArrowUpComponent,
    ArrowDownComponent,
    FileMedicalAltComponent,
    HomeComponent,
    UserComponent,
    UsersComponent,
    UserCardComponent,
    PulseComponent,
    MenuComponent,
    WellnessComponent,
    NotesComponent,
    BellComponent,
    CardiogramComponent,
    HealthInsuranceComponent,
    FlaskComponent,
    UsersExchangeComponent,
    InfoCircleComponent,
    DashboardComponent,
    AccessComponent,
    AudioComponent,
    FacilityComponent,
    FoodComponent,
    ProgressComponent,
    AssessmentComponent,
    MedicationComponent,
    EmailComponent,
    TaskComponent,
    FileSecureComponent,
    FileEditComponent,
    ProcedureComponent
  ]
})
export class IconsLibraryModule { }
