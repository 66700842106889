import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-file-medical-alt',
  templateUrl: './file-medical-alt.component.html',
  styleUrls: ['./file-medical-alt.component.css'],
})
export class FileMedicalAltComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
