import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-close-circle-filled',
  templateUrl: './close-circle-filled.component.html',
  styleUrls: ['./close-circle-filled.component.css'],
})
export class CloseCircleFilledComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
