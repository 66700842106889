<svg
[attr.width]="size"
[attr.height]="size"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.92 12.1202C17.8724 11.9974 17.801 11.8853 17.71 11.7902L12.71 6.79019C12.6168 6.69695 12.5061 6.62299 12.3842 6.57253C12.2624 6.52207 12.1319 6.49609 12 6.49609C11.7337 6.49609 11.4783 6.60188 11.29 6.79019C11.1968 6.88342 11.1228 6.99411 11.0723 7.11594C11.0219 7.23776 10.9959 7.36833 10.9959 7.50019C10.9959 7.76649 11.1017 8.02188 11.29 8.21019L14.59 11.5002H7C6.73478 11.5002 6.48043 11.6055 6.29289 11.7931C6.10536 11.9806 6 12.235 6 12.5002C6 12.7654 6.10536 13.0198 6.29289 13.2073C6.48043 13.3948 6.73478 13.5002 7 13.5002H14.59L11.29 16.7902C11.1963 16.8831 11.1219 16.9937 11.0711 17.1156C11.0203 17.2375 10.9942 17.3682 10.9942 17.5002C10.9942 17.6322 11.0203 17.7629 11.0711 17.8848C11.1219 18.0066 11.1963 18.1172 11.29 18.2102C11.383 18.3039 11.4936 18.3783 11.6154 18.4291C11.7373 18.4798 11.868 18.506 12 18.506C12.132 18.506 12.2627 18.4798 12.3846 18.4291C12.5064 18.3783 12.617 18.3039 12.71 18.2102L17.71 13.2102C17.801 13.1151 17.8724 13.0029 17.92 12.8802C18.02 12.6367 18.02 12.3636 17.92 12.1202Z"
    [attr.fill]="color"
  />
</svg>
