<ng-container>
  <div id="overlay" style="height: 100vh;">
    <div style="height: 100vh;" class="ellipsis">
      <div class="card" >
        <div class="card-body">
          <!-- <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div> -->
          <div class="d-flex justify-content-center gap-4 align-items-center">
            <div class="spinner-border ml-auto text-aqua-blue" role="status" aria-hidden="true"></div>
            <strong>Please wait...</strong>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>