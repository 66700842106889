import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-check-circle-filled',
  templateUrl: './check-circle-filled.component.html',
  styleUrls: ['./check-circle-filled.component.css'],
})
export class CheckCircleFilledComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
