<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 24 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17 12.1115C17 13.4493 17.2685 14.7714 17.787 16H6.21301C6.73157 14.7714 7.00002 13.4493 7.00002 12.1115V9C7.00002 6.23858 9.23859 4 12 4C14.7614 4 17 6.23858 17 9V12.1115ZM5.00002 9C5.00002 5.13401 8.13402 2 12 2C15.866 2 19 5.13401 19 9V12.1115C19 13.3534 19.2892 14.5783 19.8446 15.6892L20.2764 16.5528C20.6089 17.2177 20.1254 18 19.382 18H4.61805C3.87467 18 3.39117 17.2177 3.72362 16.5528L4.15543 15.6892C4.71086 14.5783 5.00002 13.3534 5.00002 12.1115V9ZM10.3716 19.5213C10.026 19.2621 10 19.0519 10 19H8.00002C8.00002 19.7929 8.41884 20.5568 9.17159 21.1213C9.92151 21.6838 10.9388 22 12 22C13.0612 22 14.0785 21.6838 14.8284 21.1213C15.5812 20.5568 16 19.7929 16 19H14C14 19.0519 13.974 19.2621 13.6284 19.5213C13.269 19.7909 12.6873 20 12 20C11.3127 20 10.731 19.7909 10.3716 19.5213Z"
    [attr.fill]="color"
  />
  <circle *ngIf="badge" cx="19" cy="5" r="4" [attr.fill]="badgeColor" stroke="white" stroke-width="2" />
</svg>
