import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css'],
})
export class UserCardComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
