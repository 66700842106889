import { Component,Input } from '@angular/core';

@Component({
  selector: 'icon-arrow-right',
  templateUrl: './arrow-right.component.html',
  styleUrls: ['./arrow-right.component.css']
})
export class ArrowRightComponent {
  @Input() size=24;
  @Input() color='#000';
}
