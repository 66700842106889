import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
