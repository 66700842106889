import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-info-circle',
  templateUrl: './info-circle.component.html',
  styleUrls: ['./info-circle.component.css'],
})
export class InfoCircleComponent {
  @Input() size = 20;
  @Input() color = '#000';
}
