<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 273 273"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M219.147 140.159V114.639C219.147 77.4094 194.401 45.8549 160.495 35.5487V23.9941C160.495 10.7638 149.731 0 136.501 0C123.27 0 112.506 10.7638 112.506 23.9941V35.5487C78.6001 45.8549 53.8541 77.4088 53.8541 114.639V140.159C53.8541 172.861 41.3889 203.87 18.755 227.474C16.5369 229.787 15.9135 233.2 17.1708 236.148C18.4281 239.095 21.3234 241.008 24.528 241.008H97.315C101.03 259.239 117.189 273 136.501 273C155.813 273 171.97 259.239 175.686 241.008H248.473C251.678 241.008 254.573 239.095 255.83 236.148C257.087 233.2 256.464 229.787 254.246 227.474C231.612 203.87 219.147 172.861 219.147 140.159ZM128.503 23.9941C128.503 19.584 132.091 15.9961 136.501 15.9961C140.911 15.9961 144.499 19.584 144.499 23.9941V32.3804C141.866 32.1266 139.199 31.9922 136.501 31.9922C133.803 31.9922 131.135 32.1266 128.503 32.3804V23.9941ZM136.501 257.004C126.074 257.004 117.184 250.317 113.882 241.008H159.119C155.818 250.317 146.927 257.004 136.501 257.004ZM41.7664 225.012C59.9812 200.595 69.8502 171.047 69.8502 140.159V114.639C69.8502 77.8876 99.7496 47.9883 136.501 47.9883C173.252 47.9883 203.151 77.8876 203.151 114.639V140.159C203.151 171.047 213.02 200.595 231.235 225.012H41.7664Z"
    [attr.fill]="color"
  />
  <path
    d="M240.479 114.635C240.479 119.052 244.06 122.633 248.477 122.633C252.894 122.633 256.475 119.052 256.475 114.635C256.475 82.5895 243.996 52.4619 221.336 29.8024C218.213 26.6794 213.149 26.6789 210.025 29.8024C206.902 32.9259 206.902 37.9897 210.025 41.1132C229.664 60.7516 240.479 86.8621 240.479 114.635Z"
    [attr.fill]="color"
  />
  <path
    d="M24.5273 122.633C28.9444 122.633 32.5254 119.052 32.5254 114.635C32.5254 86.8623 43.3409 60.7518 62.9788 41.1134C66.1023 37.9899 66.1023 32.9261 62.9788 29.8026C59.8558 26.6791 54.7914 26.6791 51.6679 29.8026C29.0084 52.4621 16.5293 82.5892 16.5293 114.635C16.5293 119.052 20.1103 122.633 24.5273 122.633Z"
    [attr.fill]="color"
  />
</svg>
