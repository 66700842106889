import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-users-exchange',
  templateUrl: './users-exchange.component.html',
  styleUrls: ['./users-exchange.component.css'],
})
export class UsersExchangeComponent {
  @Input() size = 20;
  @Input() color = '#000';
}
