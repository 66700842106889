import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-flask',
  templateUrl: './flask.component.html',
  styleUrls: ['./flask.component.css']
})
export class FlaskComponent {
  @Input() size=24;
  @Input() color='#000';
}
