import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent {
  @Input() size = 24;
  @Input() color = '#000';
  @Input() badge=false;
  @Input() badgeColor='#EB000E';

}
