<svg
  [attr.width]="size"
  [attr.height]="size * 0.78"
  viewBox="0 0 18 14"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447717 18 1C18 1.55229 17.5523 2 17 2L1 2C0.447715 2 0 1.55228 0 1ZM0 7C0 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 0 7.55228 0 7ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14L17 14C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12L1 12Z"
    [attr.fill]="color"
  />
</svg>
