import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-angle-down',
  templateUrl: './angle-down.component.html',
  styleUrls: ['./angle-down.component.css'],
})
export class AngleDownComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
