<svg
  [attr.width]="size"
  [attr.height]="size*1.5"
  viewBox="0 0 8 14"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.54001 6.29004L1.88001 0.640037C1.78705 0.546308 1.67645 0.471914 1.55459 0.421145C1.43273 0.370377 1.30202 0.344238 1.17001 0.344238C1.038 0.344238 0.907295 0.370377 0.785436 0.421145C0.663576 0.471914 0.552975 0.546308 0.460012 0.640037C0.273761 0.827399 0.16922 1.08085 0.16922 1.34504C0.16922 1.60922 0.273761 1.86267 0.460012 2.05004L5.41001 7.05004L0.460012 12C0.273761 12.1874 0.16922 12.4409 0.16922 12.705C0.16922 12.9692 0.273761 13.2227 0.460012 13.41C0.552627 13.5045 0.663072 13.5797 0.784947 13.6312C0.906823 13.6827 1.03771 13.7095 1.17001 13.71C1.30232 13.7095 1.4332 13.6827 1.55508 13.6312C1.67695 13.5797 1.7874 13.5045 1.88001 13.41L7.54001 7.76004C7.64152 7.66639 7.72253 7.55274 7.77793 7.42624C7.83334 7.29974 7.86195 7.16314 7.86195 7.02504C7.86195 6.88693 7.83334 6.75033 7.77793 6.62383C7.72253 6.49733 7.64152 6.38368 7.54001 6.29004Z"
    [attr.fill]="color"
  />
</svg>
