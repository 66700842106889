import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.css']
})
export class FoodComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
