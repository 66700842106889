<svg
  [attr.width]="size * 1.5"
  [attr.height]="size"
  viewBox="0 0 12 8"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11 5.40994L6.71 1.16994C6.61704 1.07622 6.50644 1.00182 6.38458 0.951053C6.26272 0.900284 6.13201 0.874146 6 0.874146C5.86799 0.874146 5.73728 0.900284 5.61542 0.951053C5.49356 1.00182 5.38296 1.07622 5.29 1.16994L1.05 5.40994C0.956272 5.50291 0.881877 5.61351 0.831109 5.73537C0.78034 5.85723 0.754202 5.98793 0.754202 6.11994C0.754202 6.25196 0.78034 6.38266 0.831109 6.50452C0.881877 6.62638 0.956272 6.73698 1.05 6.82994C1.23736 7.01619 1.49081 7.12074 1.755 7.12074C2.01919 7.12074 2.27264 7.01619 2.46 6.82994L6 3.28994L9.54 6.82994C9.72626 7.01469 9.97766 7.11884 10.24 7.11994C10.3716 7.1207 10.5021 7.09548 10.6239 7.04572C10.7457 6.99595 10.8566 6.92263 10.95 6.82994C11.0471 6.74033 11.1254 6.63236 11.1805 6.51228C11.2356 6.39221 11.2664 6.2624 11.2711 6.13037C11.2757 5.99833 11.2541 5.86669 11.2076 5.74303C11.1611 5.61938 11.0905 5.50617 11 5.40994Z"
    [attr.fill]="color"
  />
</svg>
