<svg
  [attr.width]="size"
  [attr.height]="size * 0.85"
  viewBox="0 0 26 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.6831 12.4728H12.0505C11.7225 12.4728 11.4563 12.2074 11.4563 11.8793C11.4563 11.758 11.5051 11.6408 11.5922 11.5561C11.9602 11.1988 12.2695 10.7177 12.4934 10.1991C12.539 10.2325 12.5878 10.2569 12.6432 10.2569C12.9981 10.2569 13.4165 9.47216 13.4165 8.93733C13.4165 8.40249 13.3669 7.9686 13.0111 7.9686C12.9688 7.9686 12.9248 7.97593 12.8792 7.98733C12.854 6.53669 12.4877 4.72705 10.2727 4.72705C7.96156 4.72705 7.69211 6.53343 7.66606 7.98244C7.63349 7.97593 7.60093 7.9686 7.57081 7.9686C7.21507 7.9686 7.16623 8.40249 7.16623 8.93733C7.16623 9.47216 7.58302 10.2569 7.93876 10.2569C7.98272 10.2569 8.02342 10.2447 8.06169 10.2227C8.28474 10.7331 8.59 11.2045 8.95226 11.5586C9.03936 11.6432 9.0882 11.7588 9.0882 11.8809C9.0882 12.209 8.82201 12.4752 8.49395 12.4752H7.86061C6.50766 12.4752 5.41113 13.5717 5.41113 14.9247V15.6036C5.41113 16.0302 5.75711 16.3769 6.18448 16.3769H14.3592C14.7858 16.3769 15.1317 16.0302 15.1317 15.6036V14.9247C15.1325 13.5701 14.036 12.4728 12.6831 12.4728Z"
    [attr.fill]="color"
  />
  <path
    d="M20.0168 5.75293H13.1616C13.3594 6.19089 13.5011 6.72979 13.5597 7.3965C13.7062 7.48686 13.8161 7.63258 13.9057 7.80841H20.0168C20.3342 7.80841 20.5898 7.55198 20.5898 7.23532V6.32602C20.5898 6.01017 20.3342 5.75293 20.0168 5.75293Z"
    [attr.fill]="color"
  />
  <path
    d="M20.0167 9.49805H14.0456C13.8836 10.1102 13.4847 10.7509 12.932 10.9218C12.9157 10.9519 12.897 10.978 12.8799 11.0081V11.5535H20.0159C20.3325 11.5535 20.589 11.2971 20.589 10.9796V10.0711C20.5898 9.75447 20.3342 9.49805 20.0167 9.49805Z"
    [attr.fill]="color"
  />
  <path
    d="M20.0167 13.2437H15.3555C15.6632 13.7305 15.8439 14.3044 15.8439 14.9222V15.2991H20.0167C20.3342 15.2991 20.5898 15.0427 20.5898 14.726V13.8167C20.5898 13.5001 20.3342 13.2437 20.0167 13.2437Z"
    [attr.fill]="color"
  />
  <path
    d="M22.48 0.0380859H3.52077C1.576 0.0380859 0 1.61409 0 3.55886V17.541C0 19.4866 1.576 21.0618 3.52077 21.0618H22.48C24.4248 21.0618 26 19.4858 26 17.541V3.55886C26 1.61409 24.4248 0.0380859 22.48 0.0380859ZM24.3385 17.541C24.3385 18.5659 23.5049 19.3995 22.48 19.3995H3.52077C2.49588 19.3995 1.66229 18.5659 1.66229 17.541V3.55886C1.66229 2.53397 2.49588 1.70038 3.52077 1.70038H22.48C23.5049 1.70038 24.3385 2.53397 24.3385 3.55886V17.541Z"
    [attr.fill]="color"
  />
</svg>
