<svg
  [attr.width]="size"
  [attr.height]="size * 0.86"
  viewBox="0 0 22 19"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.9 8.42406H16.566C16.4894 8.41341 16.4116 8.41341 16.335 8.42406H16.148C16.0905 8.45761 16.0354 8.49521 15.983 8.53657C15.9218 8.57794 15.863 8.62302 15.807 8.67158C15.7703 8.71758 15.7372 8.76649 15.708 8.81784C15.6576 8.88872 15.6134 8.96402 15.576 9.04286L13.816 14.0045L9.229 1.29104C9.15193 1.07384 9.01134 0.886181 8.82634 0.753564C8.64134 0.620947 8.42091 0.549805 8.195 0.549805C7.96909 0.549805 7.74866 0.620947 7.56366 0.753564C7.37866 0.886181 7.23808 1.07384 7.161 1.29104L4.62 8.42406H1.1C0.808262 8.42406 0.528472 8.5426 0.322183 8.75359C0.115892 8.96458 0 9.25075 0 9.54914C0 9.84754 0.115892 10.1337 0.322183 10.3447C0.528472 10.5557 0.808262 10.6742 1.1 10.6742H5.412H5.665H5.83C5.89252 10.6434 5.95155 10.6057 6.006 10.5617C6.06725 10.5204 6.12602 10.4753 6.182 10.4267L6.281 10.2804C6.33343 10.211 6.37773 10.1354 6.413 10.0554L8.195 4.95881L12.771 17.8073C12.8479 18.0247 12.9884 18.2126 13.1734 18.3455C13.3584 18.4784 13.5789 18.5497 13.805 18.5498C14.0311 18.5497 14.2516 18.4784 14.4366 18.3455C14.6216 18.2126 14.7621 18.0247 14.839 17.8073L17.369 10.6742H20.9C21.1917 10.6742 21.4715 10.5557 21.6778 10.3447C21.8841 10.1337 22 9.84754 22 9.54914C22 9.25075 21.8841 8.96458 21.6778 8.75359C21.4715 8.5426 21.1917 8.42406 20.9 8.42406Z"
    [attr.fill]="color"
  />
</svg>
