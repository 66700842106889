import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-file-edit',
  templateUrl: './file-edit.component.html',
  styleUrls: ['./file-edit.component.css']
})
export class FileEditComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
