import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
