import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
