import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  @Input() size = 24;
  @Input() color = '#000';
}
