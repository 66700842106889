import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.css']
})
export class WellnessComponent {
  @Input() size=24;
  @Input() primaryColor='#000';
  @Input() secondaryColor='#00000050';

}
