import { Component, Input} from '@angular/core';

@Component({
  selector: 'icon-close-circle',
  templateUrl: './close-circle.component.html',
  styleUrls: ['./close-circle.component.css']
})
export class CloseCircleComponent {
  @Input() size=24;
  @Input() color='#000';
}
